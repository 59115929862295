import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import colors from '~/styles/colors';
import { Container, Row, Col } from 'react-bootstrap';

import * as Style from './Footer.styles';
import * as Text from '~/styles/text';

import LogoDark from '~/images/logo-dark.svg';
import ArrowDownIcon from '~/images/menu/arrow-down-gray.svg';

import AwarenessImg from '~/images/footer/awareness.png';
import CriptoAwardsImg from '~/images/footer/cripto-awards.png';
import Power300Img from '~/images/footer/power-300.png';

import baseUrl from '~/config';

function Footer({ backgroundColor }) {
  const { t, i18n } = useTranslation();

  const [showDropdown, setShowDropdown] = useState('');

  return (
    <Style.Container backgroundColor={backgroundColor}>
      <Container>
        <Row>
          <Col lg={4}>
            <img src={LogoDark} className="logo" alt="" />

            <Col lg={10}>
              <Text.Heading5
                fontSize="16px"
                marginTop={47}
                color="#757893"
                lineHeight={150}
              >
                {t('footer.institutional.description')}
              </Text.Heading5>
            </Col>

            <Col lg={10} className="award-logo-container">
              <img src={CriptoAwardsImg} />
              <img src={Power300Img} />
              <img className="vertical-img" src={AwarenessImg} />
            </Col>

            <ul className="social-media-container">
              <li>
                <a href="https://twitter.com/capitual" target="_blank">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Twitter</title>
                    <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://instagr.am/capitual" target="_blank">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Instagram</title>
                    <g>
                      <circle cx="12.145" cy="3.892" r={1} />
                      <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                      <path d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
                    </g>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://fb.com/capitual" target="_blank">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Facebook</title>
                    <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/c/Capitual" target="_blank">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Youtube</title>
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/capitual/"
                  target="_blank"
                >
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>LinkedIn</title>
                    <path d="M8.814,5.141c0.143,0 0.258,0.116 0.258,0.259l0,0.671c0.579,-0.631 1.532,-1.187 2.822,-1.187c3.618,0 4.106,2.621 4.106,5.185l0,5.672c0,0.143 -0.116,0.259 -0.258,0.259l-3.206,0c-0.143,0 -0.258,-0.116 -0.258,-0.259l0,-5.028c0,-1.407 -0.104,-2.484 -1.41,-2.484c-1.2,0 -1.667,0.671 -1.667,2.396l0,5.116c0,0.143 -0.115,0.259 -0.258,0.259l-3.205,0c-0.142,0 -0.258,-0.116 -0.258,-0.259l0,-10.341c0,-0.143 0.116,-0.259 0.258,-0.259l3.076,0Zm-5.09,0c0.143,0 0.258,0.116 0.258,0.259l0,10.341c0,0.143 -0.115,0.259 -0.258,0.259l-3.212,0c-0.142,0 -0.258,-0.116 -0.258,-0.259l0,-10.341c0,-0.143 0.116,-0.259 0.258,-0.259l3.212,0Zm-1.604,-5.141c-1.169,0 -2.12,0.952 -2.12,2.122c0,1.17 0.951,2.123 2.12,2.123c1.167,0 2.117,-0.953 2.117,-2.123c0,-1.17 -0.95,-2.122 -2.117,-2.122Z" />
                  </svg>
                </a>
              </li>
            </ul>
          </Col>

          <Col className="col" xs={6} md={4} lg={2}>
            <Text.Heading2Bold fontSize="19px" marginBottom={30}>
              {t('footer.institutional.title')}
            </Text.Heading2Bold>

            <ul className="footer-sub-menu">
              {/* <li>
                <a>{t("footer.institutional.about")}</a>
              </li> */}
              <li>
                <a href="https://exchange.capitual.com">
                  {t('footer.institutional.fees')}
                </a>
              </li>
              <li>
                <a href="https://blog.capitual.com">
                  {t('footer.institutional.blog')}
                </a>
              </li>
              <li>
                <a href="https://press.capitual.com">
                  {t('footer.help.pressroom')}
                </a>
              </li>
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/careers`}>
                  {t('footer.institutional.careers')}
                </a>
              </li>
              {/* <li>
                <a>{t("footer.institutional.careers")}</a>
              </li> */}
            </ul>

            <Text.Heading2Bold fontSize="19px" marginBottom={30}>
              {t('footer.contact.title')}
            </Text.Heading2Bold>

            <a href="mailto:suporte@capitual.com" className="link">
              {t('footer.contact.email')}
            </a>
          </Col>
          <Col className="col" xs={6} md={4} lg={2}>
            <Text.Heading2Bold fontSize="19px" marginBottom={30}>
              {t('footer.products.title')}
            </Text.Heading2Bold>

            <ul className="footer-sub-menu">
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/#CapConta`}>
                  {t('footer.products.banking')}
                </a>
              </li>
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/#CapWallets`}>
                  {t('footer.products.wallets')}
                </a>
              </li>
              <li>
                <a href="https://cappay.capitual.com">
                  {t('footer.merchants.capPay')}
                </a>
              </li>
              <li>
                <a href="https://business.capitual.com">
                  {t('footer.products.corporate')}
                </a>
              </li>
              {/* <li>
                <a>{t("footer.products.settle")}</a>
              </li> */}
            </ul>
          </Col>
          <Col className="col" xs={6} md={4} lg={2}>
            <Text.Heading2Bold fontSize="19px" marginBottom={30}>
              {t('footer.legal.title')}
            </Text.Heading2Bold>

            <ul className="footer-sub-menu">
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/legal/terms-of-use`}>
                  {t('footer.legal.tos')}
                </a>
              </li>
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/legal/privacy-policy`}>
                  {t('footer.legal.pp')}
                </a>
              </li>

              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/legal/cookie-policy`}>
                  Política de cookies
                </a>
              </li>
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/legal/exercise-of-the-rights-of-the-data-subjects`}>
                  {t('footer.legal.edt')}
                </a>
              </li>
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/legal/prohibited-businesses`}>
                  {t('footer.legal.pb')}
                </a>
              </li>
              <li>
                <a href={`${baseUrl.ENDPOINT[0]}/legal/kyc`}>
                  {t('footer.legal.kyc')}
                </a>
              </li>
            </ul>
          </Col>
          <Col className="col" xs={6} md={4} lg={2}>
            <Text.Heading2Bold fontSize="19px" marginBottom={30}>
              {t('footer.help.title')}
            </Text.Heading2Bold>

            <ul className="footer-sub-menu">
              <li>
                <a href="https://help.capitual.com">
                  {t('footer.help.helpCenter')}
                </a>
              </li>
              <li>
                <a href="https://security.capitual.com">
                  {t('footer.help.security')}
                </a>
              </li>
              <li>
                <a href="https://dev.capitual.com">
                  {t('footer.help.developers')}
                </a>
              </li>
              <li>
                <a href="https://status.capitual.com">
                  {t('footer.help.status')}
                </a>
              </li>
              <li>
                <a href="https://opensource.capitual.com">
                  {t('footer.help.openSource')}
                </a>
              </li>
              <li>
                <a href="https://building.capitual.com/">
                  {t('footer.help.engineering')}
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      <div className="footer">
        <Container>
          <Row>
            <Col className="col-center" xs={12} md={12} lg={6}>
              <div className="translate-container">
                {Object.keys(i18n.options.resources).map((item) => (
                  <a
                    href="#"
                    key={item}
                    onClick={() => i18n.changeLanguage(item)}
                    className={item === i18n.language ? 'active' : ''}
                  >
                    {i18n.options.resources[item].translation.language}
                  </a>
                ))}
              </div>
            </Col>
            <Col className="col-center" xs={12} md={12} lg={6}>
              <div className="date">
                <Text.Heading1Regular fontSize="16px" color="#757893">
                  © {new Date().getFullYear()} Capitual Instituição de
                  Pagamentos LTDA. 34.942.560/0001-87
                </Text.Heading1Regular>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Style.Container>
  );
}

export default Footer;
